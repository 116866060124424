import { stripRichTextWrapperTag } from '~/utils/html';
import { adapter as imageAdapter } from '~/components/cloud-image/utils';
import { productFull } from '~/utils/adapters/product';
import checklist from '~/utils/adapters/checklist';
import getPrice from '~/utils/get-price';
import { routerLinks } from '~/utils/fetch-links';

const getTitle = (title, card = {}) =>
  card.card_title ? stripRichTextWrapperTag(card.card_title) || title : title;

const marketCard = (item, i18n, $enhancedLinkSerializer) => {
  const isStandalone = item.type === 'market';
  let card = item?.data || {};

  if (!isStandalone) {
    card = (card?.card && card?.card[0]) || {};
  }

  const link = isStandalone ? card.market_link : item;
  const title = getTitle(item.title, card);

  link.rel = 'nofollow';

  return {
    title,
    description: stripRichTextWrapperTag(card.card_description) || null,
    tag: stripRichTextWrapperTag(card.card_subhead) || null,
    primaryLink: item.data?.primary_link
      ? {
          link: $enhancedLinkSerializer(item.data.primary_link),
          label:
            item.data?.primary_link_label || i18n.t('cta.obtenir-un-tarif'),
        }
      : null,
    secondaryLink: link
      ? {
          link: $enhancedLinkSerializer(link),
          label: i18n.t('cta.en-savoir-plus'),
        }
      : null,
    ariaLabel: i18n.t('cta.en-savoir-plus-aria', { title }),
    image:
      card && card.card_image
        ? imageAdapter(card.card_image, card.card_image_alt)
        : null,
  };
};

const warrantyCard = (item, i18n) => ({
  name: item.data.title,
  price:
    item.data.price &&
    getPrice(
      item.data.price,
      item.data.price_frequency,
      item.data.legal_reference,
      i18n,
      { bold: true }
    ),
  warranties: checklist(item.data.warranties),
  showOptionTitle: item.data.options?.length > 0,
  optionsTitle:
    item.data.options?.length > 0 ? getLabelOptionTitle(item, i18n) : null,
  options: item.data.options?.length > 0 ? checklist(item.data.options) : [],
  showWarrantiesTitle: !!item.data.warranties_title,
  showPictoWarranties: true,
  warrantiesTitle: item.data.warranties_title,
});

const getLabelOptionTitle = (item, i18n) => {
  let defaultLabel = i18n.t('warrantyCard.options-label');
  return item.data.options_title
    ? stripRichTextWrapperTag(item.data.options_title) || defaultLabel
    : defaultLabel;
};

const getCard = (item, i18n, $enhancedLinkSerializer) => {
  let type = item.type;
  if (type === 'masterpage') {
    type = 'market';
  } else if (type === 'product_page') {
    type = 'product';
  }

  const cardData =
    (type === 'market' && marketCard(item, i18n, $enhancedLinkSerializer)) ||
    (type === 'product' && productFull(item, i18n, $enhancedLinkSerializer)) ||
    (type === 'warranty_card' && warrantyCard(item, i18n));

  if (!cardData) {
    return null;
  }

  const cardType = type === 'warranty_card' ? 'warranty-card' : `${type}-card`;

  return {
    type: cardType,
    data: cardData,
  };
};

const fetchedItems = async (items, $prismic) => {
  if (!items) {
    return [];
  }

  const fetchIds = [];
  const fetchIdsIndex = {};
  const sanitizedItems = [...items];

  function checkLink(link = {}) {
    const linkData = link.data;

    return (
      linkData && (linkData.market || linkData.product || linkData.submarket)
    );
  }

  sanitizedItems.forEach(({ featured_block_card }, index) => {
    const { type, id } = featured_block_card;
    if (type !== 'product' && type !== 'product_page') {
      return;
    }

    let card = featured_block_card.data;

    if (type === 'product_page') {
      card =
        (featured_block_card.data?.card && featured_block_card.data?.card[0]) ||
        {};
    }

    if (
      checkLink(card?.cta_primary_link) ||
      checkLink(card?.cta_secondary_link)
    ) {
      fetchIds.push(id);
      fetchIdsIndex[id] = index;
    }
  });

  if (fetchIds.length) {
    const cardRequest = await $prismic.api.getByIDs(fetchIds, {
      pageSize: 30,
      fetchLinks: routerLinks,
    });

    cardRequest.results.forEach((entry) => {
      const { id } = entry;
      const itemIndex = fetchIdsIndex[id];
      sanitizedItems[itemIndex].featured_block_card = entry;
    });
  }

  return sanitizedItems;
};

export const sanitizeModule = (module, i18n, $enhancedLinkSerializer) =>
  module
    .map(
      ({
        featured_block_card,
        featured_block_description,
        featured_block_legal_reference,
      }) => {
        if (!featured_block_card.data) return null;

        if (
          featured_block_card.type === 'product_page' ||
          featured_block_card.type === 'product'
        ) {
          featured_block_card.data.card_description =
            featured_block_description;
        }
        if (
          featured_block_card.type === 'product_page' ||
          featured_block_card.type === 'product' ||
          featured_block_card.type === 'warranty-card'
        ) {
          featured_block_card.data.legal_reference =
            featured_block_legal_reference
              ? featured_block_legal_reference
              : null;
        }
        return getCard(featured_block_card, i18n, $enhancedLinkSerializer);
      }
    )
    .filter(Boolean);

// Particulier
const adapter = async (
  { primary, items },
  data,
  { app: { i18n }, $prismic, $enhancedLinkSerializer }
) => {
  const itemsData = await fetchedItems(items, $prismic);

  if (!itemsData) {
    return null;
  }

  return {
    title: stripRichTextWrapperTag(primary.featured_block_title),
    fixed: primary.featured_block_fixed_cards_width,
    type: primary.featured_block_type,
    cards: sanitizeModule(itemsData, i18n, $enhancedLinkSerializer),
  };
};

// Pro
const proAdapter = async (
  { primary, items },
  data,
  { app: { i18n }, $prismic, $enhancedLinkSerializer }
) => {
  const itemsData = await fetchedItems(items, $prismic);

  const sorted = itemsData.reduce((acc, item) => {
    if (!acc[item.featured_block_tag]) {
      acc[item.featured_block_tag] = [];
    }
    acc[item.featured_block_tag].push(item);
    return acc;
  }, {});

  return {
    title: stripRichTextWrapperTag(primary.featured_block_title),
    tabs: Object.keys(sorted).map((key) => {
      return {
        name: key,
        cards: sorted[key]
          .map(
            ({
              featured_block_card,
              featured_block_description,
              featured_block_legal_reference,
            }) => {
              if (
                featured_block_card.type === 'product_page' ||
                featured_block_card.type === 'product'
              ) {
                featured_block_card.data.card_description =
                  featured_block_description;
              }
              if (
                !!featured_block_legal_reference &&
                (featured_block_card.type === 'product_page' ||
                  featured_block_card.type === 'product' ||
                  featured_block_card.type === 'warranty-card')
              ) {
                featured_block_card.data.legal_reference =
                  featured_block_legal_reference;
              }

              return getCard(
                featured_block_card,
                i18n,
                $enhancedLinkSerializer
              );
            }
          )
          .filter(Boolean),
      };
    }),
  };
};

export const Pro = proAdapter;

export default adapter;
